import axios from "axios";

let defaultUrl = process.env.REACT_APP_RMSHARE

const base = axios.create({baseURL: defaultUrl});

const baseRequest = ({ url, method, params, data, headers, ...rest }) =>
  base({
    url,
    method,
    params,
    data,
    headers: {
      ...headers,
    },
    ...rest
  }).then(res => res.data)

export const request = options =>
  baseRequest(options).then(response => response);
