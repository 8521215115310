import React, {useEffect, useMemo, useState} from 'react'
import Modal from 'react-modal'
import {modalDefaultStyles} from '../../App'
import {Formik} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import {ModalButton, ModalTitle} from '../common/ModalParts'

import {Field, FieldName, Form, MainFormPart, SeparateFormPart,} from './components/common'
import {ControlLabel, FormControl, FormGroup, ModalBody, ModalFooter} from 'react-bootstrap'
import {RoomSelect} from './components/RoomSelect'
import {getLastRoom} from './helpers/get-last-room'
import {useReservationsSearch} from './helpers/use-reservations-search'
import {useSelector} from 'react-redux'
import {specialServiceTypesSelectors} from '../../Modules/special-service-types'
import * as ccAPI from '../../Modules/api/ccAPI/requests'
import uuid from "uuid/v4";
import {Button} from "../buttons";
import {FaCartPlus, FaTimes} from "react-icons/fa";

const Flex = styled.div`
    display: flex;
    gap: 5px;
    align-items: end;
    margin-top: 15px;

    & > div {
        width: 100%;
    }

    button {
        margin: 0;
        padding: 5px 10px;

        svg {
            vertical-align: text-top;
        }
    }
`

const ValidationSchemaWithoutReservationPk = Yup.object().shape({
    guestName: Yup.string().required(),
    bookingNumber: Yup.string().required(),
    services: Yup.object().required().nullable(false)
        .test(services => Object.values(services).filter(it => it === null).length === 0)
})

const ValidationSchemaWithReservationPk = Yup.object().shape({
    guestName: Yup.string().required(),
    bookingNumber: Yup.string().required(),
    reservationPk: Yup.string().required(),
    services: Yup.object().required().nullable(false)
        .test(services => Object.values(services).filter(it => it === null).length === 0)
})

function normalizeValues(values, Schema) {
    return Schema.cast(values)
}

function getFieldPlaceholder(text, mode) {
    if (mode === 'automatic') {
        return text + ' (заполнится автоматически при выборе брони)'
    }

    return text
}

function getPkInputPlaceholder(options, isLoading, isLoaded, isFailed) {
    if (isFailed) return 'Не удалось загрузить брони'
    if (isLoading) return 'Загрузка броней..'
    if (!isLoaded) return 'Для загрузки броней введите номер брони'
    if (options.length === 0) return 'Броней не найдено'
    return 'Выберите бронь'
}

function getPkInputDisabled(options, isLoading, isLoaded, isFailed) {
    if (isFailed) return true
    if (isLoading) return true
    if (!isLoaded) return true
    return options.length === 0;

}

function mapReservationsToOptions(reservations) {
    return reservations.map(reservation => {
        const {
            pk,
            guest_name,
            start,
            end,
            reserved_days,
            room_names,
        } = reservation

        if (reserved_days.length === 0)
            return {
                label: `${guest_name}, с ${start} до ${end} (нет дней - желательно создать бронь заново)`,
                value: pk,
            }

        const lastDay = reserved_days[reserved_days.length - 1]

        const room = lastDay.room
            ? `${lastDay.room.room_id}, ${lastDay.room.name}`
            : room_names || 'неизвестная комната'

        return {
            label: `${guest_name}, с ${start} до ${end} (${room})`,
            value: pk,
        }
    })
}


export const SellListActionModalWithPk = ({
                                              title,
                                              lcode,
                                              isRefund,
                                              close,
                                              submitAction,
                                              hasOtherOption,
                                              ...restProps
                                          }) => {
    const [mode] = useState('automatic')
    const {
        foundResults,
        isLoading,
        isLoaded,
        isFailed,
        loadReservations,
        reset,
    } = useReservationsSearch()

    const [searchString, setSearchString] = useState('')
    const [inputPriceFields, setInputPriceFields] = useState([])

    const livingService = useSelector(specialServiceTypesSelectors.livingService)
    const upgrade = useSelector(specialServiceTypesSelectors.upgrade)
    const parkingService = useSelector(specialServiceTypesSelectors.parkingService)
    const earlyCheckinService = useSelector(specialServiceTypesSelectors.earlyCheckinService)
    const lateCheckoutService = useSelector(specialServiceTypesSelectors.lateCheckoutService)
    const livingWithAFineService = useSelector(specialServiceTypesSelectors.livingWithAFineService)

    useEffect(() => {
        setInputPriceFields([livingService.pk, upgrade.pk, parkingService.pk, lateCheckoutService.pk, earlyCheckinService.pk, livingWithAFineService.pk])
    }, [livingService, upgrade, parkingService]);

    const specialServices = useSelector(specialServiceTypesSelectors.items)
    const itemsByPk = useSelector(specialServiceTypesSelectors.itemsByPk)

    const search = async (searchString) => {
        setSearchString(searchString)
        await loadReservations(searchString)
    }

    const ValidationSchema =
        mode === 'automatic'
            ? ValidationSchemaWithReservationPk
            : ValidationSchemaWithoutReservationPk

    const reservationPkOptions = useMemo(() => {
        return mapReservationsToOptions(foundResults)
    }, [foundResults])

    return (
        <Modal
            defaultStyles={{
                ...modalDefaultStyles,
                content: {
                    ...modalDefaultStyles.content,
                    width: 800,
                    minWidth: 800,
                    maxWidth: 'auto',
                },
            }}
            onAfterClose={() => {
                reset()
                setSearchString('')
            }}
            {...restProps}
        >
            <Formik
                initialValues={{
                    room: '',
                    guestName: '',
                    bookingNumber: '',
                    reservationPk: '',
                    services: {[uuid()]: null}
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    let normalizedValues
                    let checkList = isRefund
                        ? await ccAPI
                            .getChecksByUUID(values.reservationPk)
                            .then(res => res.details)
                        : []
                    if (mode === 'automatic') {
                        const {reserved_days} = foundResults.find(
                            ({pk}) => pk === values.reservationPk
                        )
                        const roomId =
                            reserved_days[reserved_days.length - 1]?.room?.room_id

                        normalizedValues = normalizeValues(values, ValidationSchema, roomId)
                    } else {
                        normalizedValues = normalizeValues(values, ValidationSchema)
                    }
                    const {services} = normalizedValues

                    submitAction({
                        ...normalizedValues,
                        checkList,
                        services: Object.values(services),
                    })

                    actions.setSubmitting(false)
                    close()
                }}
                render={({
                             values,
                             touched,
                             errors,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isValid,
                             isSubmitting,
                             setFieldValue,
                         }) => {
                    const clearReservationData = () => {
                        setFieldValue('reservationPk', '')
                        setFieldValue('guestName', '')
                        setFieldValue('bookingNumber', '')
                        setFieldValue('room', '')
                    }

                    return (
                        <Form onSubmit={handleSubmit}>
                            <ModalTitle>{title}</ModalTitle>
                            <ModalBody style={{maxHeight: '65vh', overflow: 'auto'}}>
                                <SeparateFormPart>
                                    <FieldName>Бронь</FieldName>

                                    <Field
                                        name="searchString"
                                        placeholder="Введите номер брони для поиска (минимум 4 символа)"
                                        value={searchString}
                                        onChange={event => {
                                            search(event.target.value)
                                        }}
                                        style={{marginBottom: 15}}
                                    />
                                    <FormControl
                                        name="reservationPk"
                                        componentClass="select"
                                        value={values.reservationPk}
                                        disabled={hasOtherOption ? false : getPkInputDisabled(
                                            reservationPkOptions,
                                            isLoading,
                                            isLoaded,
                                            isFailed
                                        )}
                                        onChange={event => {
                                            handleChange(event)
                                            setFieldValue('guestName', '')
                                            setFieldValue('bookingNumber', '')
                                            setFieldValue('room')

                                            const {value} = event.target

                                            if (!value) return

                                            if (value === "other") {
                                                setFieldValue('guestName', '-')
                                                setFieldValue('bookingNumber', '-')
                                                setFieldValue('room')
                                                return
                                            }

                                            const reservation = foundResults.find(
                                                ({pk}) => pk === value
                                            )
                                            const {guest_name, booking_number} = reservation

                                            setFieldValue('guestName', guest_name)
                                            setFieldValue('bookingNumber', booking_number)
                                            setFieldValue('room', getLastRoom(reservation))
                                        }}
                                    >
                                        {hasOtherOption && <option key="other" value="other">
                                            Другое
                                        </option>}
                                        <option key="placeholder" value="">
                                            {getPkInputPlaceholder(
                                                reservationPkOptions,
                                                isLoading,
                                                isLoaded,
                                                isFailed
                                            )}
                                        </option>
                                        {reservationPkOptions.map(({label, value}) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </FormControl>
                                </SeparateFormPart>

                                <MainFormPart>
                                    <Field
                                        name="guestName"
                                        placeholder={getFieldPlaceholder('Имя гостя', mode)}
                                        value={values.guestName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.guestName && errors.guestName}
                                        disabled={true}
                                    />
                                    <Field
                                        name="bookingNumber"
                                        placeholder={getFieldPlaceholder('Номер брони', mode)}
                                        value={values.bookingNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.bookingNumber && errors.bookingNumber}
                                        disabled={true}
                                        style={{marginBottom: 15}}

                                    />
                                    <RoomSelect
                                        name="room"
                                        value={values.room}
                                        onChange={handleChange}
                                        disabled={true}
                                    />

                                    {Object.entries(values.services).map(([k, v], index) => <FormGroup key={k}>
                                        <Flex>
                                            <FormGroup style={{marginBottom: 0}}>
                                                <ControlLabel>Сервис</ControlLabel>
                                                <FormControl
                                                    componentClass="select"
                                                    value={v?.pk || ""}
                                                    onChange={({target}) => {
                                                        const data = {...values.services}
                                                        data[k] = itemsByPk[+target.value]
                                                        setFieldValue("services", data)
                                                    }}
                                                >
                                                    <option value="">Выберите услугу</option>
                                                    {specialServices.map(it => <option key={it.pk}
                                                                                       value={it.pk}>{it?.name}</option>)}
                                                </FormControl>
                                            </FormGroup>

                                            {
                                                inputPriceFields.includes(v?.pk) && <FormGroup style={{marginBottom: 0}}>
                                                    <ControlLabel>Стоимость</ControlLabel>
                                                    <FormControl
                                                        type="number"
                                                        value={v.price}
                                                        placeholder="Введите стоимость"
                                                        onChange={({target}) => {
                                                            const data = {...values.services}
                                                            data[k] = {...data[k], price: +target.value}
                                                            setFieldValue("services", data)
                                                        }}
                                                    />
                                                </FormGroup>
                                            }

                                            <FormGroup style={{width: '20%', marginBottom: 0}}>
                                                <ControlLabel>Количество</ControlLabel>
                                                <FormControl
                                                    type="quantity"
                                                    value={v?.quantity || 1}
                                                    placeholder="Введите количество"
                                                    onChange={({target}) => {
                                                        const data = {...values.services}
                                                        data[k] = {...data[k], quantity: +target.value}
                                                        setFieldValue("services", data)
                                                    }}
                                                />
                                            </FormGroup>

                                            <Button type="danger" size="lg" onClick={() => {
                                                const data = {...values.services}
                                                if (Object.keys(data).length === 1)
                                                    data[k] = null
                                                else delete data[k]
                                                setFieldValue("services", data)
                                            }}><FaTimes/></Button>

                                            {index === 0 && <Button size="lg" onClick={() => {
                                                setFieldValue("services", {...values.services, [uuid()]: null})
                                            }}><FaCartPlus/></Button>}
                                        </Flex>
                                    </FormGroup>)}

                                </MainFormPart>
                            </ModalBody>
                            <ModalFooter>
                                <ModalButton
                                    bsStyle="danger"
                                    style={{marginLeft: 0, marginBottom: 0}}
                                    onClick={close}
                                >
                                    Закрыть
                                </ModalButton>
                                <ModalButton
                                    type="submit"
                                    bsStyle="success"
                                    style={{marginLeft: 'auto'}}
                                    disabled={!isValid || isSubmitting}
                                >
                                    Произвести продажу
                                </ModalButton>
                            </ModalFooter>
                        </Form>
                    )
                }}
            />
        </Modal>
    )
}
