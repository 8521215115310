import * as types from './types'
import {onMailChange, onNameChange, onPhoneChange, setFirstPassportName,} from '../reservation/ReservationModule'
import {reservationSelectors} from '../reservation'
import {LoyaltyError} from '../api/loyaltyAPI/helpers/LoyaltyError'
import {loyaltyPurchaseActions} from '../loyalty-purchase'
import {phoneNumber as loyaltyPhoneNumberSelector} from './selectors'
import {isOnline} from '../helpers/checkInternetConnection'
import {LogsAPI} from '../logs-api'
import {settingsSelectors} from '../settings'
import {callModal, modalTemplates,} from '../../Components/dynamic-modal'
import {ccAPI} from '../api/ccAPI'
import {loyaltyAPI} from '../api/loyaltyAPI'
import Cookies from 'js-cookie'
import {COOKIES} from '../helpers/types'
import {differenceInSeconds} from 'date-fns'
import moment from 'moment'
import {parsePhoneNumber} from "libphonenumber-js/mobile";

const connectLoyaltyRequest = () => ({
    type: types.CONNECT_LOYALTY_REQUEST,
})

const connectLoyaltySuccess = ({
                                   phoneNumber,
                                   fullName,
                                   email,
                                   bonuses,
                                   pendingBonuses,
                                   level,
                               }) => dispatch => {
    const logData = JSON.stringify({
        phoneNumber,
        fullName,
        email,
        bonuses,
        pendingBonuses,
        level,
    })

    LogsAPI.custom(`Лояльность успешно подключена: ${logData}`)

    dispatch({
        type: types.CONNECT_LOYALTY_SUCCESS,
        payload: {
            phoneNumber,
            fullName,
            email,
            bonuses,
            pendingBonuses,
            level,
        },
    })
}

const connectLoyaltyFailure = error => dispatch => {
    LogsAPI.custom(`Ошибка при подключении лояльности: ${error}`)

    dispatch({
        type: types.CONNECT_LOYALTY_FAILURE,
        payload: {
            error,
        },
    })
}

const connectLoyaltyCancel = () => dispatch => {
    LogsAPI.event('Подключение лояльности было отменено')

    dispatch({
        type: types.CONNECT_LOYALTY_CANCEL,
    })
}

export const fillFields = ({
                               fullName = '',
                               phoneNumber = '',
                               email = '',
                               passportName = '',
                           }) => (dispatch, getState) => {
    const state = getState()
    const reservation = reservationSelectors.everything(state)
    const {guest_name, guest_phone, guest_mail, passport} = reservation

    const set = (currentValue, nextValue, storeHandler, force = false) => {
        if (!currentValue || force) {
            dispatch(storeHandler(nextValue))
        }
    }

    set(guest_name, fullName, onNameChange)
    set(guest_phone, phoneNumber, onPhoneChange, true)
    set(guest_mail, email, onMailChange)

    if (passport.length > 0) {
        const firstPassportName = passport[0].name

        set(firstPassportName, passportName, setFirstPassportName)
    }
}

const startSendingRegistrationSMS = () => ({
    type: types.START_SENDING_REGISTRATION_SMS,
})

const stopSendingRegistrationSMS = () => ({
    type: types.STOP_SENDING_REGISTRATION_SMS,
})

export const sendRegistrationSMS = ({language}) => async (
    dispatch,
    getState
) => {
    dispatch(startSendingRegistrationSMS())

    const state = getState()

    const phoneNumber = reservationSelectors.phoneNumber(state)

    if (!phoneNumber) {
        callModal(
            modalTemplates.alert({
                title: 'Ошибка',
                text: 'В брони не указан номер телефона',
            })
        )
        return
    }
    const enabled = JSON.parse(Cookies.get(COOKIES.REGISTR_TIME) || null)
    if (enabled) {
        const seconds = differenceInSeconds(moment(enabled.expired).toDate(), new Date())
        return alert(
            `Для повторной отправки кода подождите ${seconds} секунд.`
        )
    } else {
        const expired = new Date()
        expired.setSeconds(expired.getSeconds() + 60)
        Cookies.set(COOKIES.REGISTR_TIME, {
            set: new Date(),
            expired
        }, {expires: expired})
        LogsAPI.event(
            `Отправляем SMS с ссылкой на регистрацию на номер ${phoneNumber}`
        )

        try {
            await ccAPI.sendLoyaltyRegistrationSMS({
                phoneNumber,
                language,
            })

            callModal(
                modalTemplates.alert({
                    title: 'Успех',
                    text: 'Сообщение было отправлено',
                })
            )
        } catch (err) {
            callModal(
                modalTemplates.alert({
                    title: 'Ошибка',
                    text: 'Не удалось отправить SMS',
                })
            )
        }
    }
    dispatch(stopSendingRegistrationSMS())
}

export const setupLoyalty = () => async (dispatch, getState) => {
    LogsAPI.event('Начато подключение лояльности')

    const cancelFlag = Symbol('Cancel')

    const state = getState()
    const reservation = reservationSelectors.everything(state)

    const getClient = async () => {
        function logError(message) {
            LogsAPI.custom(`Отмена получения данных клиента: ${message}`)
        }

        const {guest_phone} = reservation

        let phoneNumber = null

        phoneNumber = guest_phone

        if (!phoneNumber) {
            const {action, params} = await callModal(
                modalTemplates.prompt({
                    title: 'Номер телефона',
                    text: 'Введите номер телефона клиента',
                })
            )

            if (action !== modalTemplates.prompt.actions.enter) {
                logError('Не введен номер телефона')
                return cancelFlag
            }

            phoneNumber = params.value
        }

        LogsAPI.event(
            `Введен номер телефона для подключения лояльности: ${phoneNumber}`
        )

        const balanceRes = await loyaltyAPI.getBalance({phoneNumber})

        if (!balanceRes.errorCode) {
            return balanceRes.client
        }

        if (balanceRes.errorCode !== 3) {
            throw new LoyaltyError(balanceRes.errorCode)
        }

        LogsAPI.event('Лояльность: Клиент не был найден')

        callModal(
            modalTemplates.alert({
                title: 'Ошибка',
                text:
                    'Клиент с таким номером телефона не был найден в программе лояльности',
            })
        )

        return cancelFlag
    }

    dispatch(connectLoyaltyRequest())

    try {
        const isConnectedToInternet = isOnline()

        if (!isConnectedToInternet) {
            callModal(
                modalTemplates.alert({
                    title: 'Ошибка',
                    text: [
                        'Данные из программы лояльности не могут быть загружены,' +
                        ' так как нет подключения к интернету.',
                        'Если клиент с таким номером телефона есть в программе,' +
                        ' количество его бонусов не изменится при сохранении брони.',
                    ],
                })
            )
            throw new Error('Нет подключения к интернету')
        }

        const clientRes = await getClient()

        if (clientRes === cancelFlag) {
            dispatch(connectLoyaltyCancel())
            return
        }

        const {fullName, phoneNumber, email} = clientRes

        dispatch(
            fillFields({
                fullName,
                phoneNumber,
                email,
                passportName: fullName,
            })
        )

        dispatch(connectLoyaltySuccess(clientRes))
    } catch (err) {
        const message = err.message || 'Неизвестная ошибка'

        dispatch(connectLoyaltyFailure(message))
    }
}

export const updatePhoneNumber = () => async (dispatch, getState) => {
    const state = getState()

    const phoneNumbers = {
        reservation: reservationSelectors.phoneNumber(state),
        loyalty: loyaltyPhoneNumberSelector(state),
    }

    dispatch(connectLoyaltyRequest())

    try {
        const updateRes = await loyaltyAPI.updateClient({
            phoneNumber: phoneNumbers.loyalty,
            client: {
                phoneNumber: phoneNumbers.reservation,
            },
        })

        if (updateRes.errorCode) {
            throw new LoyaltyError(updateRes.errorCode)
        }

        const {client} = updateRes

        dispatch(connectLoyaltySuccess(client))
    } catch (err) {
        const message = err.message || 'Неизвестная ошибка'

        dispatch(connectLoyaltyFailure(message))
    }
}

async function getClient({phoneNumber}) {
    const isConnectedToInternet = isOnline()

    if (!isConnectedToInternet) {
        callModal(
            modalTemplates.alert({
                title: 'Ошибка',
                text:
                    'Не удалось проверить наличие клиента с таким номером телефона' +
                    ' в лояльности, так как отсутствует подключение к интернету.',
            })
        )
        throw new Error('Нет подключения к интернету')
    }

    const clientRes = await loyaltyAPI.getBalance({phoneNumber})

    if (clientRes.errorCode) {
        throw new LoyaltyError(clientRes.errorCode)
    }

    const {client} = clientRes

    return client
}

export const getClientData = phone => async dispatch => {
    try {
        const {number: phoneNumber = null} = parsePhoneNumber(phone, "RU")
        if (phoneNumber) {
            return loyaltyAPI.getBalance({phoneNumber}).then(({client = null}) => client)
        }
        return null
    } catch (e) {
        return null
    }
}


export const checkNewReservation = () => async (dispatch, getState) => {
    const state = getState()

    const isLoyaltyEnabled = settingsSelectors.isLoyaltyEnabled(state)

    if (!isLoyaltyEnabled) {
        return
    }

    LogsAPI.event('Подключаем лояльность для новой брони')

    const isLoyaltyAllowed = reservationSelectors.isLoyaltyAllowed(state)

    if (!isLoyaltyAllowed) {
        LogsAPI.custom(
            'Автоматическое подключение лояльности отменено -' +
            ' лояльность не разрешена для данной брони'
        )
        return
    }

    dispatch(connectLoyaltyRequest())

    try {
        const phoneNumber = reservationSelectors.phoneNumber(state)

        if (!phoneNumber) {
            throw new Error('Не указан номер телефона')
        }

        const client = await getClient({phoneNumber})

        const {fullName, email, phoneNumber: loyaltyPhoneNumber} = client

        dispatch(
            fillFields({
                fullName,
                email,
                passportName: fullName,
                phoneNumber: loyaltyPhoneNumber,
            })
        )

        dispatch(connectLoyaltySuccess(client))
    } catch (err) {
        const message = err.message || 'Неизвестная ошибка'

        LogsAPI.custom(`Ошибка подключения лояльности: ${message}`)

        dispatch(connectLoyaltyCancel())
    }
}

export const checkExistingReservation = () => async (dispatch, getState) => {
    const state = getState()

    const isLoyaltyEnabled = settingsSelectors.isLoyaltyEnabled(state)

    if (!isLoyaltyEnabled) {
        return
    }

    LogsAPI.event('Подключаем лояльность для существующей брони')

    const isLoyaltyAllowed = reservationSelectors.isLoyaltyAllowed(state)

    if (!isLoyaltyAllowed) {
        LogsAPI.custom(
            'Автоматическое подключение лояльности отменено -' +
            ' лояльность не разрешена для данной брони'
        )
        return
    }

    dispatch(connectLoyaltyRequest())

    try {
        const phoneNumber = reservationSelectors.phoneNumber(state)

        if (!phoneNumber) {
            throw new Error('Не указан номер телефона')
        }

        const isConnectedToInternet = isOnline()

        if (!isConnectedToInternet) {
            callModal(
                modalTemplates.alert({
                    title: 'Ошибка',
                    text: [
                        'Не удалось подключить программу лояльности, так как' +
                        ' отсутствует подключение к интернету.',
                        'Бонусы клиента не будут изменены.',
                    ],
                })
            )

            throw new Error('Нет подключения к интернету')
        }

        const client = await getClient({phoneNumber})

        const {fullName, email, phoneNumber: loyaltyPhoneNumber} = client

        dispatch(
            fillFields({
                fullName,
                email,
                passportName: fullName,
                phoneNumber: loyaltyPhoneNumber,
            })
        )

        dispatch(connectLoyaltySuccess(client))
    } catch (err) {
        const message = err.message || 'Неизвестная ошибка'

        LogsAPI.custom(`Не удалось автоматически подключить лояльность: ${message}`)

        dispatch(connectLoyaltyCancel())
    }
}

export const reset = () => dispatch => {
    dispatch({type: types.RESET})
    dispatch(loyaltyPurchaseActions.reset())
}
