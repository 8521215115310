import * as types from './types'
import {getDepositsList, releaseDeposit} from '../api/ccAPI/requests'

const loadRequest = () => ({
    type: types.LOAD_REQUEST,
})

const loadSuccess = ({items}) => ({
    type: types.LOAD_SUCCESS,
    payload: {items},
})

const loadFailure = () => ({
    type: types.LOAD_FAILURE,
})

export const load = (params) => async (dispatch) => {
    dispatch(loadRequest())
    try {
        const items = await getDepositsList(params)
        dispatch(loadSuccess({items}))
    } catch {
        dispatch(loadFailure())
    }
}

export const update = (data) => async (dispatch) => {
        const item = await releaseDeposit(data)
        dispatch({type: types.UPDATE_REQUEST, payload: {item}})
}

export const reset = () => ({
    type: types.RESET,
})
