import {request, requestError} from './request'
import store from '../../../store'
import {settingsLoaded} from '../../settings/utils'
import moment from 'moment'

export const getBlacklistRecords = () =>
    request({
        url: '/guestsblacklist/',
        method: 'get',
    })

export const addPassportToBlacklist = ({
                                           name,
                                           passport_number,
                                           reason,
                                           booking_number,
                                           lcode,
                                           admin
                                       }) =>
    requestError({
        url: '/guestsblacklist/',
        method: 'post',
        data: {
            name,
            passport_number,
            reason,
            booking_number,
            lcode,
            admin,
            created_at: new Date().toISOString(),
            is_confirmed: false,
        },
    })

export const getPassportBlacklistRecords = ({passport_number}) =>
    request({
        url: `/guestsblacklist/${passport_number}/`,
        method: 'get',
    })

export const getFiles = () =>
    request({
        url: '/downloadables/',
        method: 'get',
    })

export const getSpecialServiceTypes = () =>
    request({
        url: '/service/',
        method: 'get',
    })

export const getAdminServicesSalesV2 = ({from, to}) =>
    request({
        url: '/v2/bonuses/',
        method: 'get',
        params: {
            from,
            to,
        },
    })

export const getAdminServicesSales = ({date, date_until, admin, lcode}) =>
    request({
        url: '/service_transactions/sales/',
        method: 'get',
        params: {
            date,
            date_until,
            admin,
            lcode,
        },
    })

export const getAdminBonusRate = () =>
    request({
        url: '/admin_bonus_rate/',
        method: 'get',
    }).then(items => {
        const [bonusRate] = items
        return bonusRate.value
    })

export const getLocalPrepayeds = async () => {
    const {session} = store.getState()
    return await getPrepaidByDate({lcode: session.lcode}).then(it => it.results)
}

export const getPrepayedsByBookingNumber = async booking_number => {
    const prepayeds = await getPrepaidByDate()

    return prepayeds.filter(prepayed => {
        return prepayed.booking_number === booking_number
    })
}

export const getLocalPrepayedsByBookingNumber = async booking_number => {
    const prepayeds = await getLocalPrepayeds()

    return prepayeds.filter(prepayed => {
        return prepayed.booking_number === booking_number
    })
}

export const getPrepaidByDate = async ({date, page = 1, lcode}) => {
    const dateRes = date ? moment(date).format('YYYY-MM-DD') : null
    return request({
        url: '/prepaid/',
        method: 'get',
        params: {
            date: dateRes,
            page,
            lcode
        }
    })
}

export const getLocalNotes = async () => {
    await settingsLoaded

    const state = store.getState()
    const {lcode} = state.session

    return request({
        url: `/notes/`,
        method: 'get',
        params: {lcode}
    })
}

export const getLocalNotesByBookingNumber = async booking_number => {
    const notes = await getLocalNotes()

    return notes.filter(note => {
        return note.booking_number === booking_number
    })
}

export const sendLoyaltyRegistrationSMS = ({phoneNumber, language}) =>
    request({
        url: `/sms/loyalty_registration/${language}`,
        method: 'post',
        data: {
            phone_number: phoneNumber,
        },
    })

export const sendLoyaltyConfirmationSMS = ({phoneNumber}) =>
    request({
        url: '/sms/send_bonuses_confirmation_sms/',
        method: 'post',
        data: {
            phone_number: phoneNumber,
        },
    })

export const getWashingMachinePassword = password =>
    requestError({
        url: '/password/use/',
        data: {type: 'washing_machine', password: password},
        method: 'post',
    }).then(response => response)

export const getBankCheckPassword = password =>
    requestError({
        url: '/password/use/',
        data: {type: 'bank_check', password: password},
        method: 'post',
    }).then(response => response)

export const getInventoryState = ({position_pk, lcode, date}) =>
    request({
        url: '/inventory/state/',
        method: 'get',
        params: {
            position_pk,
            lcode,
            date,
        },
    })

export const getInventoryMovementExcel = ({position_pk, lcode, start, end}) =>
    request({
        url: `/inventory/movement/excel/`,
        method: 'get',
        params: {
            position_pk,
            lcode,
            start,
            end,
        },
        responseType: 'blob',
    })

export const getBreakfastActions = (data = null) => request({
    url: `/breakfast_actions/`,
    method: 'get',
    params: data,
})


export const getBreakfastActionsList = () => {
    const {session} = store.getState()
    return request({
        url: `/breakfast_actions/list/`,
        method: 'get',
        params: {lcode: session.lcode},
    })
}

export const createBreakfastAction = ({
                                          action,
                                          booking_number_uuid,
                                          service_uuid,
                                          lcode,
                                          qty,
                                          breakfast,
                                          order_date,
                                          delivery_date,
                                          source
                                      }) =>
    request({
        url: '/breakfast_action/',
        method: 'post',
        data: {
            action,
            booking_number_uuid,
            service_uuid,
            lcode,
            qty,
            breakfast,
            order_date,
            delivery_date,
            source,
        },
        log: true,
    })

export const breakfastGiveaway = (pk) => {
    return requestError({
        url: `/breakfast_action/giveaway/${pk}/`,
        method: 'post',
    })
}

export const getChangeDatePassword = password =>
    requestError({
        url: '/password/use/',
        data: {type: 'reservation_dates', password: password},
        method: 'post',
    }).then(response => response)

export const getChangePhonePassword = password => {
    return requestError({
        url: '/password/use/',
        data: {type: 'reservation_guest_phone_number', password: password},
        method: 'post',
    }).then(response => response)
}

export const getRepairDatePassword = password => {
    return requestError({
        url: '/password/use/',
        data: {type: 'repair', password: password},
        method: 'post',
    }).then(response => response)
}

export const signIn = data =>
    request({
        url: '/token/',
        method: 'post',
        data,
        useToken: false,
    })

export const getAdmin = () =>
    request({
        url: '/whoami/',
        method: 'get',
    })

export const updateBreakfastAction = (pk, data) =>
    request({
        url: `/breakfast_action/${pk}/`,
        method: 'patch',
        data: data,
    })

export const getCCFlags = () =>
    request({
        url: `/featureflags/`,
        method: 'get',
    })

export const getHotelCCFlags = (lcode) =>
    request({
        url: `/featureflags/${lcode}`,
        method: 'get',
    })

export const getChecksByUUID = uuid =>
    request({
        url: '/v2/checks/',
        params: {
            booking_number_uuid: uuid,
        },
        method: 'get',
    })

export const getHotels = () =>
    request({
        url: '/lcodes/',
        method: 'get',
    })

export const getRoomsCall = lcode =>
    request({
        url: `/rooms/${lcode}/`,
        method: 'get',
    })

export const getRoomsCallByDate = date =>
    request({
        url: `/rooms/physical/available/${date}`,
        method: 'get',
    })

export const getReservationsCall = lcode =>
    request({
        url: `/reservation/?lcode=${lcode}`,
        method: 'get',
    })

export const searchCall = (query) =>
    request({
        url: `/reservations/search/`,
        method: 'get',
        params: query
    })

export const getReservationCall = pk =>
    request({
        url: `/reservation/${pk}/`,
        method: 'get',
    })

export const getMultipleReservationsCall = reservations => {
    const promises = reservations.map(pk => getReservationCall(pk))
    return Promise.all(promises)
}

let reservationCallsChain = Promise.resolve()

export const getReservationQueued = pk => {
    const next = reservationCallsChain.then(() => {
        return getReservationCall(pk)
    })

    reservationCallsChain = next.catch(() => null)
    return next
}

export const getWubookPricesCall = (lcode, roomId, guestsNumber) =>
    request({
        url: `/prices/${lcode}/${roomId}/${guestsNumber}/`,
        method: 'get'
    })

export const getWubookModifiedPricesCall = (lcode, roomId, guestsNumber) =>
    request({
        url: `/modified-prices/${lcode}/${roomId}/${guestsNumber}/`,
        method: 'get'
    })

export const getWubookReservationsCall = (params) =>
    request({
        url: `/reservations/check-in/`,
        method: 'get',
        params
    })

export const getWubookReservationsCallByDate = ({date, params}) =>
    request({
        url: `/reservations/check-in/${date}/`,
        method: 'get',
        params
    })

export const getWubookCanceled = () =>
    request({
        url: '/reservations/canceled/',
        method: 'get',
    })

export const getBreakfasts = () =>
    request({
        url: '/breakfast/',
        method: 'get',
    })

export const searchByDateCall = (lcode, date, isRefund = false) =>
    request({
        url: `/reservation/?is_ready=true&lcode=${lcode}&modified=${date}&is_refund=${isRefund}`,
        method: 'get',
    })

export const searchByStartCall = (lcode, date) =>
    request({
        url: `/reservation/?is_ready=true&lcode=${lcode}&start=${date}&is_refund=true`,
        method: 'get',
    })

export const getAllGroupReservationsCall = (lcode) =>
    request({
        url: `reservation/?is_group_reservations=true&lcode=${lcode}`,
        method: 'get',
    })

export const saveNewReservationCall = data =>
    request({
        url: '/reservation/',
        method: 'post',
        data,
    })

export const saveReservationCall = data =>
    requestError({
        url: `/reservation/${data.pk}/`,
        method: 'put',
        data,
    })

export const getSettingsUI = lcode =>
    request({
        url: `/settings/ui/${lcode}/`,
        method: 'get',
    })

export const getSettingsCleaning = lcode =>
    request({
        url: `/settings/cleaning/${lcode}/`,
        method: 'get',
    })

export const getSettingsLegal = lcode =>
    request({
        url: `/settings/legal/${lcode}/`,
        method: 'get',
    })

export const deleteReservationCall = ({pk, data}) =>
    requestError({
        url: `/reservation/${pk}/`,
        method: 'delete',
        data
    })

export const getAvailableRoomsCall = (lcode, start, end) =>
    request({
        url: `/rooms/available/${lcode}/?start=${start}&end=${end}`,
        method: 'get',
    })

export const searchAvailableRooms = (lcode, start, end) =>
    request({
        url: `/rooms/available-for-possible-reservation/${lcode}/?start=${start}&end=${end}`,
        method: 'get',
    })

export const getAllAvailableRoomsCall = (lcode, start, end) =>
    request({
        url: `rooms/available_with_repair/${lcode}/?start=${start}&end=${end}`,
        method: 'get',
    }).then(res => Object.values(res).flat(1))

export const getCapacityWithCheckins = ({lcode, date}) =>
    request({
        url: `/rooms/available/${lcode}/all`,
        method: 'get',
        params: {date}
    })

export const checkReservationInsertionPossibilityCall = bookingNumber =>
    request({
        url: `/validate-booking-number/?booking_number=${bookingNumber}`,
        method: 'get',
    })

export const cancelReservationCall = (pk) =>
    requestError({
        url: `/reservations/cancel/`,
        method: 'post',
        data: {pk},
    })

export const submitLogsCall = (data) =>
    request({
        url: '/client-log/',
        method: 'post',
        data: data
    })

export const sendFeatureSuggestion = ({message}) =>
    request({
        url: '/create-pms-suggestion/',
        method: 'post',
        data: {
            message,
        },
    })

export const getReservationsEndingToday = (date) =>
    request({
        url: `reservations/check-out/${date}`,
        method: 'get',
    })

export const getReservationsStartingToday = (date) =>
    request({
        url: `reservations/check-in/${date}`,
        method: 'get',
    })

export const getCleaningList = (date) =>
    request({
        url: '/reservations/cleaning/list/',
        method: 'get',
        params: {date}
    })

export const sendReportCall = ({message, subject, debug}) =>
    request({
        url: '/create-pms-bugreport/',
        method: 'post',
        data: {
            message,
            subject,
            debug,
        },
    })

export const updateSettingsCall = (lcode, data) =>
    request({
        url: `/settings/ui/${lcode}/`,
        method: 'put',
        data
    })

export const updateCardsCall = ({hasCard, pk}) =>
    request({
        url: `/reservation/${pk}/`,
        method: 'patch',
        data: {hasCard}
    })

export const updateReservation = ({data, pk}) =>
    request({
        url: `/reservation/${pk}/`,
        method: 'patch',
        data
    })

export const updateAccountingCall = (lcode) =>
    request({
        url: `/googlesync/force-update?lcode=${lcode}`,
        method: 'get',
    })

export const createInventory = ({
                                    booking_number,
                                    booking_number_uuid,
                                    lcode,
                                    admin,
                                    position,
                                    qty,
                                    action,
                                }) =>
    request({
        url: '/inventory/',
        method: 'post',
        data: {
            booking_number,
            booking_number_uuid,
            lcode,
            admin,
            position,
            qty,
            action,
        },
    })

export const getPhones = () =>
    request({
        url: '/contacts/',
        method: 'get',
    })

export const getReservationsByBookingNumber = bookingNumber =>
    request({
        method: 'get',
        url: `/reservation/?booking_number=${bookingNumber}`,
    })

export const getAllReservationsByBookingNumber = (bookingNumber) =>
    request({
        method: 'get',
        url: `/reservations/search/?q=${bookingNumber}`,
    })

export const getAllReservationsByBookingNumberNotCanceled = (bookingNumber) =>
    request({
        method: 'get',
        url: `/reservations/search/?q=${bookingNumber}`,
    }).then(res => res.filter(it => !it.canceled))

export const checkNewWubookCall = (lcode) =>
    request({
        url: `reservations/fetch/?lcode=${lcode}&kind=regular`,
        method: 'get',
    })

export const restorePassword = email =>
    requestError({
        url: '/recover-password/ ',
        method: 'post',
        data: {email},
        useToken: false
    }).then(() => true).catch(() => false)

export const saveCheckCallV2 = ({
                                    total,
                                    date,
                                    reservationPk,
                                    bookingNumber,
                                    lcodeId,
                                    paymentType,
                                    items,
                                    adminID,
                                }) =>
    request({
        url: '/v2/check/',
        method: 'post',
        data: {
            lcode: lcodeId,
            total: +total,
            date,
            booking_number_uuid: reservationPk,
            booking_number: bookingNumber,
            payment_type: paymentType,
            items,
            user_id: adminID,
        },
    })

export const saveCheckRefundCallV2 = data =>
    request({
        url: '/v2/refund/',
        method: 'post',
        data: data,
    })

export const createDoubleBooking = ({is_refund, amount, payment_method, lcode}) =>
    request({
        url: `/double-booking/`,
        method: 'post',
        data: {
            is_refund,
            amount,
            payment_method,
            lcode
        },
    })

export const createMixedPayment = ({is_refund, amount, payment_method, lcode}) =>
    request({
        url: `/mixed-payment/`,
        method: 'post',
        data: {
            is_refund,
            amount,
            payment_method,
            lcode
        },
    })

export const saveCheckCall = ({
                                  room,
                                  lcode,
                                  total,
                                  date,
                                  isRefund,
                                  reservationPk,
                                  bookingNumber,
                                  guestName,
                                  paymentType,
                                  items,
                                  positions,
                                  adminID,
                                  kkt
                              }) =>
    request({
        url: '/check/create/',
        method: 'post',
        data: {
            room,
            lcode,
            total,
            date,
            isRefund,
            booking_number_uuid: reservationPk,
            booking_number: bookingNumber,
            guest_name: guestName,
            payment_type: paymentType,
            positions,
            items,
            admin: adminID,
            kkt
        },
    })

export const getWashingMachines = (lcode) =>
    request({
        url: `washing-machines/${lcode}/`,
        method: 'get',
    })

export const startWashing = ({lcode, machine, service_uuid}) =>
    request({
        url: `washing-machine/${lcode}/${machine}/start/`,
        method: 'post',
        params: {
            service_uuid,
        },
    })

export const getIsWashingMachineDoorOpened = ({lcode, machine}) =>
    request({
        url: `washing-machine/${lcode}/${machine}/is-door-opened/`,
        method: 'get',
    }).then(({is_door_opened}) => is_door_opened)

export const cancelWashing = ({service_uuid}) =>
    request({
        url: `washing-operation/cancel/${service_uuid}/`,
        method: 'post',
    })

export const noShowSearchCall = (date) =>
    request({
        url: `/noshows/${date}/`,
        method: 'get',
    })

export const getGridReservations = (params) =>
    request({
        url: `/reservations/living/`,
        method: 'get',
        params
    })

export const createServiceTransaction = ({
                                             booking_number,
                                             booking_number_uuid,
                                             lcode,
                                             admin,
                                             isRefund,
                                             items,
                                             is_admin_bonuses_applied = true,
                                             special_service_id,
                                             kkt
                                         }) =>
    request({
        url: '/service_transaction/',
        method: 'post',
        data: {
            booking_number,
            booking_number_uuid,
            lcode,
            admin,
            isRefund,
            items,
            is_admin_bonuses_applied,
            special_service_id,
            kkt
        },
    })

export const checkNotPrintCall = (booking_number, kind) =>
    request({
        url: 'reservations/should-print-check-for-non-refundable/',
        method: 'post',
        data: {
            booking_number,
            kind
        },
    })

export const getReadyReservationsByBookingNumber = (bookingNumber) => {
    return getAllReservationsByBookingNumber(bookingNumber).then(res => res.filter(it => it.is_ready))
}

export const getRegistrationForms = () => request({
    url: '/registration-forms/',
    method: 'get',
})

export const createTransactionWithoutAuthCall = ({
                                                     rrn,
                                                     authCode,
                                                     lcode,
                                                     date,
                                                     total,
                                                     kkt
                                                 }) =>
    request({
        url: '/transactions/create/',
        method: 'post',
        data: {
            rrn,
            authCode,
            lcode,
            date,
            total,
            kkt
        },
    })

export const createTransactionCall = ({rrn, authCode, lcode, date, total}) =>
    request({
        url: '/transactions/auth/create/',
        method: 'post',
        data: {
            rrn,
            authCode,
            lcode,
            date,
            total,
        },
    })

export const getCanceledWarningsReservations = () => request({
    url: 'reservations/canceled-warnings/',
    method: 'get',
})


export const getLateCheckouts = date => request({
    url: `/reservations/late-check-out/${date}/`,
    method: 'get',
})

export const validateReservation = (data) =>
    requestError({
        url: 'reservation/is-rooms-available/',
        method: 'post',
        data
    })

export const checkRomAvailability = (data) => requestError({
    url: `/reservations/check-category-available/`,
    method: 'post',
    data
})

export const getMaidsList = () => request({
    url: '/maids/users/',
    method: 'get',
})

export const getMaidsSessionData = (data) =>
    requestError({
        url: '/maids/users/',
        method: 'post',
        data
    })

export const checkMaidsUri = ({uri}) => requestError({
    url: `/maids/checkin/${uri}`,
    method: 'get',
    useToken: false
})

export const confirmMaidsUri = ({uri}) => requestError({
    url: `${uri}`,
    method: 'post',
    useToken: false
})

export const getMaidsCheckedInList = (params) => requestError({
    url: '/maids/checkout/',
    method: 'get',
    params
})

export const sendMaidsCheckoutCode = (data) => requestError({
    url: '/maids/checkout/',
    method: 'post',
    data
})

export const confirmMaidsCheckout = (data) => requestError({
    url: '/maids/checkout/confirm/',
    method: 'post',
    data
})

export const getNews = (params, str = "") => requestError({
    url: `/news/${str}`,
    method: 'get',
    params
})

export const setRead = (data) => requestError({
    url: `/news/`,
    method: 'post',
    data
})

export const newNewsCount = () => requestError({
    url: `/news_count/`,
    method: 'get',
})

export const newsCategories = () => requestError({
    url: `/news_categories/`,
    method: 'get',
})

export const readAllNews = (data) => requestError({
    url: `/news/`,
    method: 'post',
    data
})

export const getOtasList = () => requestError({
    url: '/otas',
    method: 'get',
})


export const changeRoomStatus = (data) => requestError({
    url: `/rooms/status/update/`,
    method: 'post',
    data
})

export const getBreakfastsRestrictions = () => requestError({
    url: '/breakfasts/restrictions/',
    method: 'get',
})

export const checkShift = (lcode) => requestError({
    url: `/checklist/${lcode}/workshift/`,
    method: 'get',
})
export const openShift = (lcode) => requestError({
    url: `/checklist/${lcode}/open/`,
    method: 'get',
})

export const getOpenShiftCheckList = (lcode) => requestError({
    url: `/checklist/${lcode}/get_open/`,
    method: 'get',
})

export const checkListOpen = ({data, lcode}) => requestError({
    url: `/checklist/${lcode}/open/`,
    method: 'post',
    data
})

export const setCloseShift = ({data, lcode}) => requestError({
    url: `/checklist/${lcode}/close/`,
    method: 'post',
    data
})

export const getCloseShiftCheckList = (lcode) => requestError({
    url: `/checklist/${lcode}/close/`,
    method: 'get',
})


export const getReviewCheckListClose = (lcode) => requestError({
    url: `/checklist/${lcode}/review/`,
    method: 'get',
})

export const setReviewCheckListClose = ({data, lcode}) => requestError({
    url: `/checklist/${lcode}/review/`,
    method: 'post',
    data
})


export const getCommentsAboutClient = (phone) =>
    request({
        url: '/comments/',
        method: 'get',
        headers: {'X-Guest-Phone': phone},
    })

export const createCommentsAboutClient = ({phone, data}) =>
    request({
        url: `/comments/`,
        method: 'post',
        headers: {"X-Guest-Phone": phone},
        data
    })

export const updateCommentsAboutClient = ({phone, data, id}) =>
    request({
        url: `/comments/${id}/`,
        method: 'put',
        data
    })

export const deleteCommentsAboutClient = (id) =>
    request({
        url: `/comments/${id}`,
        method: 'delete',
    })

export const inventoryCheck = (data) =>
    request({
        url: '/inventory/check/',
        method: 'post',
        data
    })

export const setDeposit = (data) =>
    requestError({
        url: '/deposit/create/',
        method: 'post',
        data
    })

export const getDepositsList = (params) =>
    request({
        url: '/deposit/retrieve/',
        method: 'get',
        params
    })

export const releaseDeposit = (data) =>
    requestError({
        url: `/deposit/update/${data.id}/`,
        method: 'put',
        data
    })
export const roomOccupancy = ({start, end, lcode}) =>
    request({
        url: `/rooms/available_by_category/${lcode}/`,
        params: {start, end},
        method: 'get',
    })

export const getRoomChangesHistory = ({lcode, room_id}) =>
    request({
        url: `/rooms/status-history/${lcode}`,
        params: {room_id},
        method: 'get',
    })


export const getStickers = ({lcode}) =>
    request({
        url: `/stickers/`,
        params: {lcode},
        method: 'get',
    })

export const updateSticker = ({id, ...data}) =>
    request({
        url: `/stickers/${id}/`,
        data,
        method: 'patch',
    })


export const createSticker = (data) =>
    request({
        url: `/stickers/`,
        data,
        method: 'post',
    })


export const getCheckInsWithCleaningSecondTierList = () =>
    request({
        url: `/reservation/check-in/cleaning-second-tier/`,
        method: 'get',
    })
