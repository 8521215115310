import React from "react";
import {DebouncedTextField} from "../../../../../../common/DebouncedTextField";
import {useActions} from "../../../../../../common/hooks/use-actions";
import {loyaltyClientActions} from "../../../../../../../Modules/loyalty-client";

export const GuestInfo = ({name, phone, email, note, errors, setFieldValue}) => {

    const {getClientData} = useActions(loyaltyClientActions)

    const setPhone = async (phone) => {
        const {fullName, phoneNumber = phone, email} = await getClientData(phone) || {}
        return {fullName, phoneNumber, email}
    }

    return <div>
        <DebouncedTextField
            label="Телефон гостя"
            placeholder="+71234567890"
            value={phone}
            onChange={async val => {
                if (val)
                    setPhone(val).then(({fullName, phoneNumber, email}) => {
                        setFieldValue('phone', phoneNumber)
                        setFieldValue('name', fullName)
                        setFieldValue('email', email)
                    })
                else
                    setFieldValue('phone', val)
            }}
            containerStyle={{marginBottom: 10}}
            error={errors.phone}
        />

        <DebouncedTextField
            label="ФИО гостя"
            placeholder="Иванов Иван Иванович"
            value={name}
            onChange={val => setFieldValue('name', val)}
            containerStyle={{marginBottom: 10}}
            error={errors.name}
        />

        <DebouncedTextField
            label="Почта гостя"
            placeholder="ivanov@gmail.com"
            value={email}
            onChange={val => setFieldValue('email', val)}
            containerStyle={{marginBottom: 10}}
            error={errors.email}
        />
        <DebouncedTextField
            componentType="textarea"
            label="Примечание"
            placeholder="Полезное примечание"
            value={note}
            onChange={val => setFieldValue('note', val)}
            containerStyle={{marginBottom: 10}}
        />
    </div>
}
